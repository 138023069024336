<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{$vuetify.lang.t('$vuetify.homeDoctor.label')}}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form
          ref="form"
        >
          <v-row>

            <v-col
              cols="4"
            >
              <v-text-field
                v-model="form.realName"
                :label="$vuetify.lang.t('$vuetify.user.realName')"
                :rules="rules.realName"
              ></v-text-field>
            </v-col>

            <v-col cols="4">
               <v-select
                  v-model="form.depId"
                  :label="$vuetify.lang.t('$vuetify.user.dep')"
                  :items="departments"
                  :rules="rules.depId"
                  item-text="showName"
                  item-value="id"
                ></v-select>
            </v-col>

            <v-col
              cols="4"
            >
              <v-text-field
                v-model="form.phone"
                :label="$vuetify.lang.t('$vuetify.user.phone')"
                :rules="rules.phone"
              ></v-text-field>
            </v-col>

            <v-col
              cols="4"
            >
              <v-text-field
                v-model="form.credentialNum"
                :label="$vuetify.lang.t('$vuetify.user.credentialNum')"
                :rules="rules.credentialNum"
              ></v-text-field>
            </v-col>

            <v-col
              cols="4"
            >
              <v-text-field
                v-model="form.address"
                :label="$vuetify.lang.t('$vuetify.common.address')"
              ></v-text-field>
            </v-col>

          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="info darken-1"
        @click="handleCancel"
      >
         {{$vuetify.lang.t('$vuetify.common.cancel')}}
      </v-btn>
      <v-btn
        color="primary darken-1"
        @click="handleSubmit"
      >
         {{$vuetify.lang.t('$vuetify.common.submit')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';
  import { phone, idCard } from '@/utils/validators';

  export default {
    props: {
      editStatus: {
        type: Boolean,
        default: () => false
      },
      editItem: {
        type: Object,
        default: () => null
      }
    },
    data() {
      return {
        form: {
          id: null,
          realName: null,
          phone: null,
          depId: null,
          credentialNum: null,
          credentialType: null,
          company: null,
          companyAddr: null,
          address: null
        },
        rules: {
          realName: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.user.realName')])
          ],
          phone: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.user.phone')]),
            (v) =>
              phone.test(v) || this.$vuetify.lang.t('$vuetify.rule.format', [this.$vuetify.lang.t('$vuetify.common.phone')])
          ],
          credentialNum: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.common.identityCard')]),
            (v) =>
              idCard.test(v) || this.$vuetify.lang.t('$vuetify.rule.format', [this.$vuetify.lang.t('$vuetify.common.identityCard')])
          ],
          depId: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.user.dep')])
          ]
        }
      }
    },

    computed: {
      ...mapGetters({
        departments: 'department/departments'
      })
    },

    created () {
      this.initialize()
    },

    methods: {

      ...mapActions ({
        departmentQueryAll: 'department/queryAll'
      }),

      initialize () {
        if (this.editStatus) {
          for (let p in this.form) {
            if (p in this.editItem) {
              this.form[p] = this.editItem[p]
            } else {
              this.form[p] = null
            }
          }
        } else {
          this.resetFrom()
        }
        this.departmentQueryAll()
      },

      resetFrom () {
        for (let key in this.form) {
          this.form[key] = null
        }
        this.form.credentialType = 'I'
        this.$nextTick ( () => {
          this.$refs.form.reset()
        })

      },
      handleCancel() {
        this.$emit('cancel', true)
      },
      handleSubmit() {
        if (this.$refs.form.validate()) {
          const data = Object.assign({}, this.form)
          if (this.editStatus) {
            this.$emit('update', data);
          } else {
            this.$emit('submit', data);
          }
        }
      }
    }
  }
</script>
